import React from 'react';
import { Button } from './Button';
import './ServiceModal.css';

function ServiceModal({
    showModal,
    closeModal, 
    title, 
    goal, 
    deliverables1,
    deliverables2,
    deliverables3,
    deliverables4,
    deliverables5,
    deliverables6,
    example1, 
    example2
}){


    const isThere = (passedItem) =>{
        if(passedItem !==undefined){
            return true;
        }
    }

    return (
        <>
            {showModal ? 
            <div className='modal-background-container' onClick={closeModal}>
                <div className='service-modal-background-container'>
                    <div className='service-modal-container'>
                            <div className='service-inner-container'>
                            <div className='service-modal-left-container'>
                                <h1 className='service-title'>{title}</h1>
                                    <div className='goal-container'>
                                        <h1 className='modal-title'>Objective</h1>
                                        <p className='goal'>{goal}</p>
                                    </div>
                                    <div className='deliv-container'>
                                        <h1 className='modal-title'>Typical deliverables</h1>
                                        <ul className='deliverables-list'>
                                            <li className='deliverable'>
                                                {deliverables1}
                                            </li>
                                            <li className='deliverable'>
                                                {deliverables2}
                                            </li>
                                            <li className='deliverable'>
                                                {deliverables3}
                                            </li>
                                            <li className='deliverable'>
                                                {deliverables4}
                                            </li>
                                            <li className={isThere(deliverables5) ? 'deliverable' : 'deliverable-no-bullet'} >
                                                {deliverables5}
                                            </li>
                                            <li className={isThere(deliverables6) ? 'deliverable' : 'deliverable-no-bullet' }>
                                                {deliverables6}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='goal-container'>
                                        <h1 className='modal-title'>Examples</h1>
                                        <li className={isThere(example1) ? 'example' : 'deliverable-no-bullet'}>
                                                {example1}
                                        </li>

                                        <li className={isThere(example2) ? 'example' : 'deliverable-no-bullet'}>
                                                {example2}
                                        </li>


                                    </div>

                                
                                
                            </div>
                            <div className='close-icon-wrapper'>
                                    <i className='fas fa-times fa-times-service-modal' onClick={closeModal}></i>
                            </div>
                            <div className='service-modal-right-container'>
                                    <div className='study-modal-title-container'>
                                    <h1 className='study-modal-title'>View our case studies to learn more</h1>
                                    <Button buttonStyle='btn--outline' page='case-studies'>Case Studies</Button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            : null}
        </>
        
    );
}

export default ServiceModal;