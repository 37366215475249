import './Title.css';

const STYLES =['title--primary', 'title-big', 'title-sub', 'title--details', 'title--top', 'title--white', 'title-header'];


function Title ({children, titleStyle}){

    const checkTitleStyle = STYLES.includes(titleStyle) ? titleStyle : STYLES[0];

    return (
    <h1
    className={`title ${checkTitleStyle}`}
    >

    {children}

    </h1>
    );
}

export default Title;