import React from 'react';
import HeaderCard from './HeaderCard';
import "./About.css";
import Title from './Title';
import { Button } from './Button';
import AboutCard from './AboutCard';

function About(){
    return (
        <div className='about-container'>
            <HeaderCard
                title='About Us'
             />
            <div className='what-we-do-container'>
             <div className='our-goal-container'>
             <h1 className='our-goal-title'>What we do</h1>
                <div className='our-goal-para-container'>
                <p  className='our-goal-para' >
                We are new consulting firm seeking to solve the most critical pain points unable (or unwilling) to be addressed by traditional consulting models and pride ourselves on our ability to be agile, 
                outcome oriented and fiduciaries to our client partners. 
                Reach out in case of any questions, and even better yet, try our services to experience the difference
                    </p>
                </div>

                <div className='our-goal-para-container'>
                <p   className='our-goal-para'> </p>
                </div>
             </div>
             </div>


            <div className='findmore-container'>
                <div className='findmore-contents'>
                <Title titleStyle='title--white'>Find out more about Zai Team</Title>
                <Button buttonStyle='btn--outline' buttonSize='btn--large' page='./contact'>Request more information</Button>
                </div>
            </div>


            <div className='about-team-container'>
            <div className='teams-container'>
                    <div className='cont'>
                    <h1 className='founding-team-header'>Founding Team</h1>
                    </div>
                    <AboutCard />
            </div>
            </div>
        </div>
    );
}

export default About;