import React,{useState} from 'react';
import ServiceModal from './ServiceModal';
import './ServiceModal.css';

function OperationsCard({
    showModal,
    closeModal, 
    title, 
    goal,
    deliverables1,
    deliverables2,
    deliverables3,
    deliverables4,
    deliverables5,
    deliverables6,
    example1, 
    example2
}){

    const [showServiceModal, setshowServiceModal] = useState(false);

    const openServiceModal = () =>{
        setshowServiceModal(prev => !prev)
    }


    return (
        <>
        <li className='operations-list' onClick={openServiceModal} >
            <p className='operation-name'>{title}</p>
        </li>
        <ServiceModal 
        showModal={showServiceModal} 
        title={title}
        goal={goal}
        deliverables1={deliverables1}
        deliverables2={deliverables2}
        deliverables3={deliverables3}
        deliverables4={deliverables4}
        deliverables5={deliverables5}
        deliverables6={deliverables6}
        example1={example1}
        example2={example2}
        closeModal={() => setshowServiceModal(false)}
        />
        </>
        
    );
}

export default OperationsCard;