import React, { useRef } from 'react';
import Input from './Input';
import "./ContactCard.css";
import Title from "./Title";
import { Button } from "./Button";
import emailjs from 'emailjs-com';

function ContactCard({contactLocation, inputStyle}){

   const LOCATION = ['contact-container__footer', 'contact-container'];

   const INPUTSTYLES = 'input-footer';

   const checkLocation = LOCATION.includes(contactLocation) ? contactLocation : LOCATION[1];

   const checkInputStyles = () => {
        return false;
    }

   const form = useRef();

   const emailSuccess = () =>{
       return (
            <div>

            </div>
       );
   }



    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_6evez3t', 'template_x64vces', form.current, 'user_IF1vfjiboj5Oa1xdOomYH')
        .then((result) => {
            emailSuccess(true);
        }, (error) => {
            emailSuccess(false);
        });
        e.target.reset();

   }

    return(
        <div className="contact-div">
            
            <div className={checkLocation}>
            <div className={emailSuccess ? 'email email-success' : 'email email-failed'}>
                    {emailSuccess? 'Form submitted successfully.': 'failed'}
            </div>
            <form className='contact-form' onSubmit={sendEmail} ref={form}>
            <div className="input-row">
            <Input inputStyle={inputStyle+'_short'} placeholder='First Name' type='text' name='firstName'/>
            <Input inputStyle={inputStyle+'_short'} placeholder='Last Name' type='text' name='lastName'/>
            </div>
            <Input inputStyle={inputStyle+'_medium'} placeholder='Email' type='email' name='email'/>
            <Input inputStyle={inputStyle+'_medium'} placeholder='Subject' type='text' name='subject'/>
            <Input textarea='textarea' inputStyle={inputStyle+'_large'} placeholder='Description' name='message'/>
            <div className="submit-btn-wrapper">
            <input type='submit' className="submit-btn" value='Send Us A Message'></input>
            </div>
            </form>
            </div>
        </div>
    );
}

export default ContactCard;