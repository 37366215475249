import React from 'react';
import ContactCard from './ContactCard';
import HeaderCard from './HeaderCard';
import './Contact.css'

function Contact(){
    return(
        <div className='contact-page-div'>
            <HeaderCard 
                 title='contact'
            />
            <div className='contat-details-container'>

           
            <h1 className='contact-title'>Thank you for your interest. Please fill out the form below to inquire about our work </h1>

            <ContactCard contactLocation='contact-container' inputStyle='input' />

            </div>

        </div>
    );
}

export default Contact;