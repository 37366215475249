import React from 'react';
import { Button } from './Button';
import Title from './Title';
import './Card.css';
import model from '../assests/consulting-model.jpg';
import idealclient from '../assests/ideal-client.jpg';
import { useInView } from 'react-hook-inview'






function Card(props){

    const [ref, isVisible] = useInView({
        threshold: 1,
      })

const imgFirstBullets = (props) => {

    


    return (
        <div className= { isVisible ? 'card-container card-container-zoom' : 'card-container' }  ref={ref}>
            <div className='img-wrapper-left'>
                <img  className='img' alt='cardIMG' src={props.imageSrc}></img>
            </div>

            <div className='disc-container-right'>    
            <Title className='title--custom' titleStyle={props.titleStyle}>{props.title}</Title>
            <ul className='card-bullets'>
                <li className='bullet-points'>
                Fixed fees projects
                </li>

                <li className='bullet-points'>
                Step pricing for extensions (on case-by-case basis)                
                </li>

                <li className='bullet-points'>
                Results for commercial engagements validated with client finance
                </li>

                <li className='bullet-points'>
                Contingent pricing model available upon request
                </li>
                
            </ul>
            <Button className='learn--button' type='button--primary' buttonSize='button--large' page={props.buttonLink}> {props.buttonText}</Button>
            </div>
        </div>

    );
}

const  imgFirstCard = (props) => {

    return (
        <div className='card-container'>

            <div className='img-wrapper-left'>
                <img  className='img' alt='cardIMG' src={props.imageSrc}></img>
            </div>

            <div className='disc-container-right'>    
            <Title className='title--custom' titleStyle={props.titleStyle}>{props.title}</Title>
            <Title className='title--details' titleStyle={props.detailStyle}>{props.details}</Title>
            <Button className='learn--button' type='button--primary' buttonSize='button--large' page={props.buttonLink}> {props.buttonText}</Button>
            </div>
        </div>

    );
}




    const imgLastCard = (props) => {
        return (
            <div className='card-container'>
            <div className='disc-container-left'>    
            <Title className='title--custom' titleStyle={props.titleStyle}>{props.title}</Title>
            <Title className='title--details' titleStyle={props.detailStyle}>{props.details}</Title>
            <Button className='learn--button' type='button--primary' buttonSize='button--large' page={props.buttonLink}> {props.buttonText}</Button>
            </div>

            <div className='img-wrapper-right'>
                <img  className='img' alt='cardIMG' src={props.imageSrc}></img>
            </div>
        </div>
    
        );
    }

    const imgOnly = (props) =>{
        console.log('imgonly got called');
        return (
            <div className='imgoncard-container'>
            <Title titleStyle={props.titleStyle}>{props.title}</Title>
            <div className={props.type === 'imgOnly' ? 'img-wrapper' : 'img-wrapper-nb'}>
                <img  className='img' alt='cardIMG' src={props.imageSrc}></img>
            </div>
        </div>
    
        );
    }

    if(props.type === 'imgFirst'){
        return imgFirstCard(props);

    }
    else if(props.type === 'imgLast'){
        return imgLastCard(props);
    }
    else if(props.type === 'imgFirstBullets'){
        return imgFirstBullets(props);
    }
    else{
        return imgOnly(props);
    }
    
}

export default Card;
