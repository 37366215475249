import React, { useState } from 'react';
import beach from '../assests/img-2.jpg';
import CaseCard from './CaseCard';
import './Casestudies.css';
import rocks from '../assests/img-4.jpg';
import sand from '../assests/img-8.jpg';
import CaseModal from './CaseModal';
import retail from '../assests/retail.jpeg';
import warehouse from '../assests/warehouse.jpeg';




function Casestudies(){

    return(
        <div className='casestudies-page__div'>
            <div className='case-title-container'>
                <h1 className='case-title'>Case Studies</h1>
            </div>
            <div className='casestudies-div'  >
                <CaseCard   caseStudyNumber='1'   img={retail}  title='Procurement for F50 Grocery Retailer' detail='Effort lauded for innovative ideas, collaborative spirit and the outsized impact'  />
                <CaseCard   caseStudyNumber='2' img={warehouse} title='Procurement for large industrial distributor'  detail='CFO at large industrial distributor looking to deliver savings while improving team capabilities'  />
             </div>


        </div>
    );
}

export default Casestudies;