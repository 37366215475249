import React from 'react';
import './Button.css';
import {Link} from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from "react-router";

const STYLES = ['btn--primary', 'btn--outline', 'btn-contact'];

const SIZES = ['btn--medium', 'btn--large'];


export const Button = ({children, type, onClick, buttonStyle, buttonSize, page}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    const link = `/${page}`;

    return (
        <Link to={link}  className='btn-mobile'  onClick={ () => window.ScrollToTop()}>
            <button
            className={`btn ${checkButtonStyle} ${checkButtonSize}`}
            type={type}
            page={page}
            >
                {children}
            </button>
        </Link>
    );
}
