import React from 'react';
import { Button } from './Button';
import Title from './Title';
import './HeaderCard.css';


function HeaderCard(props){
    return (
    <div className='header-container'>
        <div className='header-contents'>
        <Title titleStyle='title-header'>{props.title}</Title>
        <div className='header-divider'> </div>
        <div className="header-desc">Founded 2019, New York, USA</div>
        </div>
    </div>
    );
}

export default HeaderCard;
