import './App.css';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Hero from './components/Hero';
import Home from './components/pages/Home';
import ServicesPage from './components/pages/ServicesPage';
import AboutUs from './components/pages/AboutUs';
import CasestudyPage from './components/pages/CasestudyPage';
import ContactPage from './components/pages/ContactPage';
import Footer from './components/Footer';
import ReactGa from 'react-ga';
import React, { useEffect } from 'react';



function App() {

    ReactGa.initialize('UA-181636303-2');
    ReactGa.pageview(window.location.pathname);




  return (
    <div className="App">
    <Router>
    <Navbar />
    <Routes >  
      <Route  path='/'  element={<Home />}> </Route>
      <Route path='/services' element={<ServicesPage/>}> </Route>
      <Route path='/case-studies' element={ <CasestudyPage />}> </Route>
      <Route path='/about-us' element={<AboutUs/>}> </Route>
      <Route path='/contact' element={<ContactPage/>}> </Route>
    </Routes>
    <Footer />

    </Router>
    </div>
  );
}

export default App;
