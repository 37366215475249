import React from 'react';
import iqbal from '../assests/iqbal.jpg';
import './AboutCard.css';

function AboutCard(){
    return (
        <div className='about-card-container'>
            <div className='about-img-wrapper'>
                <img className='about-img' src={iqbal}></img>
            </div>

            <div className='about-info-container'>
                <h1 className='about-name'>Iqbal Aasim</h1>
                <h2 className='about-role'>Founder</h2>
                <div className='about-contacts'>
                    <span className='about-social-links'>
                         <a href="mailto:iqbal@zaiteam.com?subject=Contact Iqbal Aasim">Email</a>
                    </span>
                    <span className='about-social-links'>
                         <a href="https://www.linkedin.com/in/iqbalaasim/">LinkedIn</a>
                    </span>
                    <p className='about-info-para'>
                    entrepreneur, advisor, supply chain, operations, procurement, project management, clean energy, blockchain/ distributed ledger technologies
                    </p>
                   
                </div>
            </div>  
        </div>
    );
}

export default AboutCard;
