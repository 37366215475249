import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import './Navbar.css'

function Navbar() {

    const [click, setClick] = useState(false); 
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerWidth <= 960){
            setButton(false);
        }
        else{
            setButton(true);
        }
    };


    return (
        <>
            <nav className='navbar'>
                <div className="navbar-container">
                    <Link to="/" className='navbar-logo' onClick={closeMobileMenu}>
                        Zai Team
                        <p className='sub-logo'>management consultants</p>
                    </Link>
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} ></i>
                    </div> 
                    <ul className={click ? 'nav-menu active': 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                            Home
                            </Link>                
                        </li>
                        
                        <li className='nav-item'>
                            <Link to='/services' className='nav-links' onClick={closeMobileMenu}>
                            Services
                            </Link>   
                                               
                        </li>

                        <li className='nav-item'>
                            <Link to='/case-studies' className='nav-links' onClick={closeMobileMenu}>
                            Case Studies
                            </Link>   
                                               
                        </li>

                        <li className='nav-item'>
                            <Link to='/about-us' className='nav-links' onClick={closeMobileMenu}>
                            About Us
                            </Link>   
                                               
                        </li>
                        <li className='nav-item'>
                            <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                            Contact               
                            </Link>   
                        </li>
                    </ul>


                </div>
            </nav>
        </>
    )
}

export default Navbar;
