import React from 'react';
import '../../App.css';
import About from '../About';
import Footer from '../Footer';

function Home (){
     console.log("Home geting called");
    return (
        <div className='home'>
        <About />
        </div>
    );
};

export default Home;