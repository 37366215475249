import React from 'react';
import {Button} from './Button';
import Card from './Card';
import './Hero.css';
import '../App.css';
import IconCard from './IconCard';
import Title from './Title';
import model from '../assests/consulting-model.jpg';
import Footer from './Footer';
import ideal from '../assests/2.png';
import fee from '../assests/3.jpg';


function Hero() {
    return (
        <div className='hero-container'>
            <div className='hero-video-container'>
                <div className='misson-content-container'>
                    <div className='mission--wrapper'>
                    <h1 className='mission-title'>We Accelerate Your <span className='bold'>Business Success</span></h1>
                    <p className='mission-text'>
                    We aim to partner with select clients to drive outsized positive operational impact and help them achieve technological breakthroughs</p>
                        <div  className='hero-btn'>
                        <div className='button--services__wrapper'>
                        <Button className='btn-services' buttonStyle='btn--outline' buttonSize='btn--large' page='services'>
                            Our Services
                        </Button>
                        </div>
                        <div className='button--case__wrapper'>
                        <Button className='btn-case-study' buttonStyle='btn--primary' buttonSize='btn--large' page='case-studies' children='butt'>
                            Case Studies
                        </Button>
                        </div>
                    </div>
                    </div>
                    
                </div>
            </div>
            <Card
                type='imgLast'
                className='hero-card'
                img={model}
                title='Our Consulting Model'
                titleStyle='title-big'
                details='Management consulting firm built to deliver holistic solutions using a tech-first approach'
                detailStyle='title--details'
                buttonText='Learn More'
                buttonLink='services'
                imageSrc={model}
              />
            <IconCard />

            <Card
                type='imgFirst'
                className='hero-card'
                img={ideal}
                title='Ideal Client For Us'
                titleStyle='title-big'
                details='Any organization looking to deliver impact, inject fresh perspectives and/or trying to make sense of the constantly evolving  technological landscape'
                detailStyle='title--details'
                buttonText='Get Started'
                buttonLink='services'
                imageSrc={ideal}
              />

        </div>
    );
}

export default Hero;
