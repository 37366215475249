import React from 'react';
import './IconCard.css'
import Title from './Title';

function IconCard(){
    return (
        <div className='container'>
        <Title className='icon--card__title' titleStyle='title--top'>Our Core Values</Title>
        <div className='iconcard-container'>
         <div className='icons_container--one'>
            <icon className='icon'>
            <i class="fas fa-search"></i>
            </icon>
            <h1 className='icon-title'>
            Transparency
            </h1>
            <p className='icon-detail'>
                We are highly transparent in our business relationships
            </p>
         </div>

         <div className='icons_container--two'>
            <icon className='icon'>
            <i class="far fa-handshake"></i>
            </icon>
            <h1 className='icon-title'>
            Accountability
            </h1>
            <p className='icon-detail'>
                We take full ownership of our actions
            </p>
         </div>

         <div className='icons_container--three'>
            <icon className='icon'>
            <i class="fas fa-check"></i>
            </icon>
            <h1 className='icon-title'>
            Doing right by clients
            </h1>
            <p className='icon-detail'>
                We operate as a fiduciary to our clients
            </p>
         </div>
        </div>
        </div>
    );
}

export default IconCard;