import React from 'react';
import './ClientIndustries.css';
import upArrow from '../assests/arrowUp.jpg';
import intelPic from '../assests/intel.jpg';
import panasonicPic from '../assests/panasonic.jpg';
import downArrow from '../assests/arrowDown.jpg';
import kroger from '../assests/kroger.jpg';
import coppel from '../assests/coppel.jpg';
import pepsi from '../assests/pepsico.jpg';
import ferrero from '../assests/ferrero.jpg';
import dow from '../assests/dow.jpg';
import dupont from '../assests/dupont.jpg';
import cummins from '../assests/cummins.jpg';
import grainger from '../assests/grainger.jpg';
import cnw from '../assests/C&W.jpg';
import specturm from '../assests/specturm.jpg';
import jnj from '../assests/J&J.jpg';
import tpg from '../assests/TPG.jpg';
import advent from '../assests/advent.jpg';
import platnium from '../assests/platnium.jpg';
import tmobile from '../assests/tmobile.jpg';


function ClientIndustries(){
    return(
        <div className='industires-container'>


            <ul className='industries'>

            <li className='industry_bottom'>

               

                    <div className='industry-pics__wrapper'>
                    <div className='industry-label__container'>

                        <p  className='industry-label'>
                        Private Equity
                        </p>
                    </div>
                        <img className='downarrow-img' src={downArrow}></img>
                        <img className='industry-img img-top__bottom' src={tpg} alt='industrypic'></img>
                        <img className='industry-img img-bottom__bottom'  src={advent} alt='industrypic'></img>
                        <img className='industry-img img-bottom__bottom'  src={platnium} alt='industrypic'></img>
                    </div> 

                </li>


                <li className='industry'>
                    <div className='industry-pics__wrapper'>
                        <img className='industry-img img-top' src={intelPic}></img>
                        <img className='industry-img img-bottom' src={panasonicPic}></img>
                        <img className='arrowup-img' src={upArrow}></img>
                        <div className='industry-label__container'>

                        <p className='industry-label'>Tech</p>
                        </div>
                    </div>

                </li>
                

                <li className='industry_bottom'>

                    
                    <div className='industry-pics__wrapper'>

                    <div className='industry-label__container'>

                    <p className='industry-label'>
                    Retail
                    </p>

                    </div> 

                        <img className='downarrow-img' src={downArrow}></img>
                        <img className='industry-img img-top__bottom' src={kroger} alt='krogerPic'></img>
                        <img className='industry-img img-bottom__bottom'  src={coppel} al></img>
                    </div> 

                </li>


                <li className='industry'>
                    <div className='industry-pics__wrapper'>
                        <img className='industry-img img-top' src={pepsi}></img>
                        <img className='industry-img img-bottom' src={ferrero}></img>
                        <img className='arrowup-img' src={upArrow}></img>
                        <div className='industry-label__container'>


                    <p  className='industry-label'>
                    F&B
                    </p>
                    </div>
                    </div>
                   
                </li>


                <li className='industry_bottom'>
                  
                    <div className='industry-pics__wrapper'>


                    <div className='industry-label__container'>
                    <p className='industry-label'>
                    Chemical
                    </p>
                    </div> 

                        <img className='downarrow-img' src={downArrow}></img>
                        <img className='industry-img img-top__bottom' src={dow} alt='krogerPic'></img>
                        <img className='industry-img img-bottom__bottom'  src={dupont} al></img>
                    </div> 

                </li> 


                <li className='industry'>
                    <div className='industry-pics__wrapper'>
                        <img className='industry-img img-top' src={cummins}></img>
                        <img className='arrowup-img' src={upArrow}></img>
                        <div className='industry-label__container'>
                    <p  className='industry-label'>
                    Automotive
                    </p>
                    </div>
                    </div>

                    
                </li>



                <li className='industry_bottom'>
               
                    <div className='industry-pics__wrapper'>
                    <div className='industry-label__container'>

                        <p  className='industry-label'>
                        Industrial
                        </p>

                    </div>
                        <img className='downarrow-img' src={downArrow}></img>
                        <img className='industry-img img-top__bottom' src={grainger} alt='krogerPic'></img>
                        <img className='industry-img img-bottom__bottom'  src={cnw} al></img>
                    </div> 

                </li>

                <li className='industry'>

                    <div className='industry-pics__wrapper'>
                        <img className='industry-img img-top' src={specturm}></img>
                        <img className='industry-img img-bottom' src={jnj}></img>
                        <img className='arrowup-img' src={upArrow}></img>
                           <div className='industry-label__container'>

                    <p  className='industry-label'>
                    CPG
                    </p>
                    </div>
                    </div>
                </li>


                <li className='industry_bottom'>

               

                    <div className='industry-pics__wrapper'>
                    <div className='industry-label__container'>

                        <p  className='industry-label'>
                       Telecom
                        </p>
                    </div>
                        <img className='downarrow-img' src={downArrow}></img>
                        <img className='industry-img img-bottom__bottom' src={tmobile} alt='industrypic'></img>
                    </div> 

                </li>

            </ul>

        </div>
    );
}

export default ClientIndustries;