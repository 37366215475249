import React from 'react';
import '../../App.css';
import Contact from '../Contact';
import Footer from '../Footer';
import Hero from '../Hero';

function ContactPage(){
     console.log("Home geting called");
    return (
        <div className='home'>
        <Contact />
        </div>
    );
};

export default ContactPage;