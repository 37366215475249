import React from 'react';
import '../../App.css';
import Casestudies from '../Casestudies';
import Hero from '../Hero';

function CasestudyPage(){
     console.log("Services geting called");
    return (
        <div className='home'>
        <Casestudies />
        </div>
    );
};

export default CasestudyPage;