import React, { useState } from 'react';
import './CaseCard.css';
import CaseModal from './CaseModal';
import beach from '../assests/img-2.jpg';
import rocks from '../assests/img-4.jpg';





function CaseCard({img, caseStudyNumber, title, detail, modalImg}){
    let [isOpen, setisOpen] = useState(false);

    return(
        
        <div className='casestudies-container'>
                        <h1 className='casestudy-title'>Case Study {caseStudyNumber}</h1>
            <div className='casestudy-container' onClick={() => setisOpen(true)}> 
                <img className='casestudy-img' src={img} alt='case-studyPic'></img>
                <div class="project_description">
                                <h3 class="project_description--studyNo">Case Study {caseStudyNumber} </h3>
                                <h3 class="project_description--title">{title}</h3>
                                <p class="project_description--para">
                                   {detail}
                                </p>
                </div>
            </div> 
            
            <CaseModal caseStudyNumber={caseStudyNumber} open={isOpen} onClose={ () => setisOpen(false) } > Fancy Modal </CaseModal>

        </div>
    );
}

export default CaseCard;