import React from "react";
import Input from "./Input";
import Contact from "./Contact";
import './Footer.css';
import Title from "./Title";
import { Link } from 'react-router-dom';
import ContactCard from "./ContactCard";


function Footer(){
    return (
    <div className="footer-div">
        <div className="footer-container">
            <div className="footer-left__card"> 
            <div className="footer-top__container">
                    <Link to="/" className='footer-logo'>
                                Zai Team
                                <p className='sub-logo__footer'>management consultants</p>
                    </Link>

                    <div className="icon-container__footer">
                        <a className="footer-icon__button" >
                            <icon className='footer-social__icons'>
                              
                            </icon>
                        </a>

                        <a className="footer-icon__button" >
                            <icon className='footer-social__icons'>
                               
                            </icon>
                        </a>

                        <a className="footer-icon__button" >
                            <icon className='footer-social__icons'>
                               
                            </icon>
                        </a>

                    </div>
            </div>

            <div className="footer-meidum__container">
                <div className='footer-menu'>
                        <li className='footer-item'>
                            <Link to='/' className='footer-links'>
                            Home
                            </Link>                
                        </li>
                        <li className='footer-item'>
                            <Link to='/services' className='footer-links'>
                            Services
                            </Link>   
                                               
                        </li>

                        <li className='footer-item'>
                            <Link to='/case-studies' className='footer-links'>
                            Case Studies
                            </Link>   
                                               
                        </li>

                        <li className='footer-item'>
                            <Link to='/about-us' className='footer-links'>
                            About Us
                            </Link>   
                        </li>
                    </div>
            </div>

            <div className="footer-contact__menu">

                        <li className='footer-contact__item'>
                            <icon>
                                <i class="fas fa-home"></i>
                            </icon>

                            <p className="contact-disc">
                            6751 Columbia Gateway Drive, Suite #300  Columbia MD 21046
                            </p>
                                        
                         </li>
                        
                        <li className='footer-contact__item'>
                                <icon>
                                    <i class="fas fa-envelope"></i>
                                </icon>

                                <p className="contact-disc">
                                info@zaiteam.com 
                                </p>
                                               
                        </li>

                        <li className='footer-contact__item'>

                                <icon>
                                    <i class="fas fa-phone"></i>
                                </icon>

                                <p className="contact-disc">
                                +1 630-484-7784
                                </p>  
                        </li>
                </div>

            
                <div className='copy-right-container-left'>
                    <p className="copy-right-left">AIA Capital Group LLC. © 2019. All rights reserved.</p>
                </div>
                
            </div>
            <div className="footer-border">
            <ContactCard contactLocation='contact-container__footer' inputStyle='input-footer'/>
            <div className='copy-right-container-right'>
                    <p className="copy-right-right">AIA Capital Group LLC. © 2019. All rights reserved.</p>
                </div>
            </div>
        </div>
    </div>

    );
}

export default Footer; 