import React, { Component } from 'react';
import './CaseModal.css';
import beach from '../assests/img-2.jpg';
import rocks from '../assests/img-4.jpg';
import casestudy1 from '../assests/casestudy1.jpg';
import casestudy2 from '../assests/casestudy2.png';


function CaseModal({open, children, onClose, modalImg, caseStudyNumber}){

    /* HOW TO ADD NEW CASE STUDIES

    
    STEP 1: 
    Add the new Case Study Img to the Assests Folder. 
    make sure the file name of the img is "casestudy#" ( eg: "casestudy1"  "casestudy2" and so on).

    STEP 2: 
    Import the Img from the assets folder to this file by copying this ----> " import casestudy# from '../assests/casestudy#.png'; "
    Change the # in the import to the respective casestudy number and right file type .png or .jpg depending on what your img is
    Paste the import at the top with the rest of the imports and see its the same as the other two its pretty straight forward
    

    STEP 3: 
    Add 'casestudy#' in to the list blelow (Line 31) added it after the comma in the brackets []
    
    */


    const checkCaseNumber  = () =>{

        if(caseStudyNumber ==  1){
            return casestudy1;
        }
        if(caseStudyNumber == 2){
            return casestudy2;
        }
        if(caseStudyNumber == 3){
            return casestudy1;
        }

     };



    if(!open) return null;

    return (
        <div className='modal-background-container' onClick={onClose}>
        <div className='service-modal-background-container'>
            <div className='modal-container'>
            <div className='close-icon-container'>
            <i className='fas fa-times fa-times-case-modal' onClick={onClose}></i>
            </div>
                <div className='modal-img-wrapper'>
                    <img className='modal-img' src={checkCaseNumber()} alt='case1' ></img>
                </div>
            </div>
        </div>
        </div>
        
    );
}

export default CaseModal;
