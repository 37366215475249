import React from 'react';
import '../../App.css';
import Hero from '../Hero';
import Services from '../Services';

function ServicesPage(){
     console.log("Services geting called");
    return (
        <div className='home'>
        <Services />
        </div>
    );
};

export default ServicesPage;