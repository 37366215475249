import React, { useState, useEffect } from 'react'
import {Button} from './Button'
import Title from './Title';
import './Services.css';
import Card from './Card';
import service from '../assests/service.jpeg';
import ClientIndustries from './ClientIndustries';
import target from '../assests/target.png';
import idealclient from '../assests/ideal-client.jpg';
import fee from '../assests/3.jpg';
import ServiceModal from './ServiceModal';
import OperationsCard from './OperationsCard';




function Services(){

    return (
        <div className='services-div' >                
            <div className='services-top__container'>
                <div className='services-wrapper__overview'>
                <h1 className='mission-title misson-title-service'>Professional management consulting services from <span className='orange'> Zai Team </span></h1>
                <p className='servies-text__overview'>
                Operational thought partners who will do what it takes to help achieve complex organizational strategic goals 
                by utilizing a novel approach of targeted engagements and micro-goal-setting</p>
                </div>
                <div  className='services-btn'>
                        <Button className='btn-contact' buttonStyle='btn--outline' buttonSize='btn--large' page='contact'>
                            Contact Us
                        </Button>
                </div>
            </div>

            <Title titleStyle='title--top'>We serve clients on Operational topics</Title>

            <div className='operational-container'>
                <h1 className='capabilities-title'>Capabilities</h1>
                <div className='capabilities-container'>
                    <div className='top-list-container'>
                        <ul className='top-lists'>
                            <li className='industires-functions first'>
                            Industry/Functions
                            </li>
                            <li className='industires-functions'>
                            Tech     
                            </li>
                            <li className='industires-functions'>
                            Retail
                            </li>
                            <li className='industires-functions'>
                            F&B
                            </li>
                            <li className='industires-functions'>
                            Chemical
                            </li>

                            <li className='industires-functions'>
                            Automotive
                            </li>

                            <li className='industires-functions'>
                            Industrial
                            </li>
                           
                            <li className='industires-functions'>
                            Telecom
                            </li>
                            
                            <li className='industires-functions'>
                            CPG
                            </li>
                            
                            <li className='industires-functions'>
                            Private Equity
                            </li>
                        </ul>
                   </div>

                   <div className='top-list-container'>
                        <ul className='left-lists'>
                            <li className='industires-functions'>
                            Marketing & Sales
                            </li>

                            <li className='operations'>
                                    <p className='operation-label'>Operations</p>
                                <div className='operations-container'>  
                                        <OperationsCard 
                                        title='Cost Optimization' 
                                        cardID='1'
                                        goal='identify initiatives with potential to improve overall cost profile of organization/function and/or to meet annual targets'
                                        deliverables1='Detailed assessment of organization/function'
                                        deliverables2='Initiative long-list with top-down assessed savings impact'
                                        deliverables3='Initiative prioritization and roadmap'
                                        deliverables4='Execution of select high-impact initiatives'
                                        deliverables5='Finance approval of achieved target'
                                        example1='Reducing cost of purchased good and services (direct and indirect)'
                                        example2='Identifying cost efficient supply base (suppliers)'
                                        />
                                        <OperationsCard 
                                        title='Operational Efficiency' 
                                        cardID='2'
                                        goal='attain greater operational efficiency by improving current processes, procedures, policies and/or through digitization'
                                        deliverables1='Assessment of target metrics and current efficiency'
                                        deliverables2='KPIs – current vs. target'
                                        deliverables3='Detailed activity list to achieve desired objective'
                                        deliverables4='Overall project plan and key milestones'
                                        deliverables5='Technology evaluation'
                                        example1='Improving operational efficiency of manufacturing plants'
                                        example2='Recalibration of organizational procedures or policies'
                                    
                                        />

                                        <OperationsCard 
                                        title='Due Diligence' 
                                        cardID='3'
                                        goal='conduct pre-deal or post-deal operational due diligence on portfolio company of private equity firm'
                                        deliverables1='Research report into target organization'
                                        deliverables2='Synergy potential resulting from M&A'
                                        deliverables3='Benchmarking of target’s operational KPIs'
                                        deliverables4='Overall project plan to achieve target synergies'
                                        example1='Pre-deal synergy DD from combination of target with portfolio company'
                                        example2='Post-deal rapid cost take-out'
                                        />
                                        <OperationsCard 
                                        title='Process Re-engineering' 
                                        cardID='4'
                                        goal='process re-engineering to drastically improve the functioning of organization/function tied to overall organizational goals'
                                        deliverables1='Workshops/interviews with organizational leadership'
                                        deliverables2='Gap analysis'
                                        deliverables3='Current state and future state process maps'
                                        deliverables4='Recommendations on improvement potential'
                                        deliverables5='Alignment workshops'
                                        deliverables6='Future-state process documentation'
                                        example1='Procurement process re-engineering for supply chain function'
                                        />
                                        <OperationsCard 
                                        title='Procurement / P2P' 
                                        cardID='5'
                                        goal='assessment of procurement and P2P processes and system architecture with goal of maximizing efficiency and user adoption'
                                        deliverables1='Functional interviews'
                                        deliverables2='Process maps'
                                        deliverables3='Gap analysis'
                                        deliverables4='System architecture'
                                        deliverables5='Recommendations'
                                        deliverables6='Initiative list'
                                        example1='Direct and indirect category P2P process assessment'
                                        />
                                        <OperationsCard 
                                        title='Technology Evaluation' 
                                        cardID='6'
                                        goal='evaluation of technology and associated suppliers against organizational digitization and efficiency targets'
                                        deliverables1='Technology research'
                                        deliverables2='Supplier identification and short-list'
                                        deliverables3='Supplier presentations'
                                        deliverables4='Supplier comparison report'
                                        deliverables5='Recommendation'
                                        example1='Evaluation of vendors for deployment of indirect P2P technology'
                                        />

                                        <OperationsCard 
                                        title='Strategic Sourcing' 
                                        cardID='7'
                                        goal='competitive sourcing of complex goods and services to achieve multiple objectives including cost reduction, supply continuity, etc.'
                                        deliverables1='Stakeholder interviews'
                                        deliverables2='Assessment report on savings potential, sourcing levers'
                                        deliverables3='RFI/RFP/RFQ/RFS design'
                                        deliverables4='Response analysis'
                                        deliverables5='Savings target and selection criteria'
                                        deliverables6='Supplier award and contracting'
                                        example1='Strategic sourcing of direct/indirect goods and services'
                                        />
                                         <OperationsCard 
                                        title='Operational Efficiency' 
                                        cardID='2'
                                        goal='attain greater operational efficiency by improving current processes, procedures, policies and/or through digitization'
                                        deliverables1='Assessment of target metrics and current efficiency'
                                        deliverables2='KPIs – current vs. target'
                                        deliverables3='Detailed activity list to achieve desired objective'
                                        deliverables4='Overall project plan and key milestones'
                                        deliverables5='Technology evaluation'
                                        example1='Improving operational efficiency of manufacturing plants'
                                        example2='Recalibration of organizational procedures or policies'
                                        />
                                </div>
                            </li>

                            <li className='industires-functions'>
                            Organization
                            </li>

                            <li className='industires-functions'>
                            Transformation
                            </li>

                            <li className='industires-functions'>
                            Sustainability
                            </li>

                            <li className='industires-functions'>
                            Corporate Finance
                            </li>
                        </ul>
                   </div>
                   
                </div>


            </div>


            <div className='clientIndustries-div'>
            <Title titleStyle='title--top'>Experience serving blue chip clients across various industries</Title>
            <ClientIndustries />
            </div>


            <Title titleStyle='title--top'>Target engagement</Title>
        

            <div className='target-img__container'>
           
                <div className='targetIMG-wrapper'>
                        <img className='targetIMG' src={target}></img>
                      
                </div>
                

                <div className='target-engagement__container'>
                    <p className='target-label'>
                    <p className='target-disc'>
                    Target engagement length: we believe that for most business problems a unique insight, 
                    critical progress and/or a solution can be made/found within a 6-12 week timeframe.
                    </p>
                    </p>
                 </div>
            </div>
            
            
            <div className='last-container'>
            <Card
                type='imgFirst'
                className='hero-card'
                img={fee}
                title='Flexible commercial model'
                titleStyle='title-big'
                details='We work with all types of commercial models incl. fixed-fees, variable and contingency-based. Soon, we plan to start accepting payment in cryptocurrencies.'
                buttonText='Get Started'
                detailStyle='title--details'
                buttonLink='case-studies'
                imageSrc={fee}
              />
            </div>
            
        </div>
    );
}

export default Services;